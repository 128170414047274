import { Modal, Rating } from "@mui/material";
import { useState, useEffect, useCallback, Fragment } from "react";

import NoImage from "../../../img/no-image-lg.png";
import api from "../../../services/api";
import { handleToast } from "../../../utils/handleToast";
import {
  Body,
  Container,
  Header,
  Left,
  ModalContainer,
  Product,
  Right,
} from "./styles";
import { formatPrice } from "../../../utils/formatPrice";

interface IProductImage {
  path: string;
  name: string;
  mimetype: string;
  filepath: string;
  image64: string;
}

interface IProduct {
  _id: string;
  title: string;
  images: IProductImage[];
}

interface IShopCartProduct {
  total: number;
  amount: number;
  startDate: Date;
  saleProducts: {
    _id: string;
    title: string;
    images: IProductImage[];
  };
  _id: string;
}

interface IShopCart {
  _id: string;
  products: IShopCartProduct[];
}

interface IProps {
  date?: string;
  organization?: string;
  total: number;
  status?: string;
  saleItems: {
    _id: string;
    total: number;
    products: IProduct[];
    amount: number;
    installments: number;
    installmentValue: number;
    user: string;
    organization: string;
    status: string;
    canceledReason: string;
    isActive: boolean;
    shopCart: string;
    id_pay: string;
    createdAt: Date;
    updatedAt: Date;
  };
  shopCart: IShopCart;
}

const mapStatus = (status?: string) => {
  switch (status) {
    case "SCHEDULE_AWAITING":
      return "Aguardando Confirmação";
    case "SCHEDULE_AWAITING_PAYMENT":
      return "Aguardando pagamento";
    case "SCHEDULE_CREATED":
      return "Agendamento criado";
    case "SCHEDULE_CONFIRMED":
      return "Agendamento confirmado";
    case "SCHEDULE_CANCELED":
      return "Agendamento cancelado";
    case "SCHEDULE_FINISHED":
      return "Agendamento concluído";
    default:
      return "";
  }
};

const AgendamentoCard: React.FC<IProps> = ({
  date,
  organization,
  total,
  status,
  saleItems,
  shopCart,
}) => {
  const [ratingModal, setRatingModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  const [comment, setComment] = useState("");
  const [product, setProduct] = useState<String | null>(null);
  const [rating, setRating] = useState(1);

  const renderProductImage = useCallback((images: IProductImage[]) => {
    if (!images?.length) return NoImage;

    return `data:${images[0]?.mimetype};base64,${images[0]?.image64}`;
  }, []);

  const sendRating = useCallback(async () => {
    const data = {
      organization,
      saleProduct: product,
      rating,
      message: comment,
    };
    await api.post("/products-sale-rating", { data });

    handleToast(false, "Avaliação enviada!");
  }, [comment, organization, product, rating]);

  useEffect(() => {
    if (!ratingModal) {
      setProduct(null);
    }
  }, [ratingModal]);

  return (
    <Container>
      <Header>
        <span>Agendamento realizado</span>
        <span></span>
        <span>Vendido por:</span>
        <span>Valor</span>
        <span>Status</span>
        <span>{date || "Data do agendamento"}</span>
        <span></span>
        <span>{organization || "Loja"}</span>
        <span>{formatPrice(total) || "Valor total"}</span>
        <span>{mapStatus(status)}</span>
      </Header>
      <Body>
        {shopCart?.products.map((item: IShopCartProduct) => (
          <Fragment key={item._id}>
            <Left>
              <span>
                <b>Agendado para o dia:</b>
              </span>
              <span>{date || "Data do agendamento"}</span>
              <Product>
                <img
                  src={renderProductImage(item.saleProducts.images)}
                  alt="Imagem do produto"
                />
                <div>
                  <p>{item.saleProducts.title || "Produto"}</p>
                </div>
              </Product>
            </Left>
            <Right>
              {status === "SCHEDULE_FINISHED" && (
                <button
                  className="detalhes"
                  onClick={() => {
                    setProduct(item?._id);
                    setRatingModal(true);
                  }}
                >
                  Avaliar pedido
                </button>
              )}
            </Right>
          </Fragment>
        ))}
      </Body>
      <Modal open={ratingModal} onClose={() => setRatingModal(false)}>
        <ModalContainer>
          <h4>Avalie o produto</h4>
          <Rating
            value={rating}
            onChange={(e: any) => setRating(e.target.value)}
          />
          <h3>Comentário</h3>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <div className="buttons">
            <button
              onClick={() => {
                sendRating();
                setRatingModal(false);
              }}
            >
              {"Confirma"}
            </button>
            <button
              onClick={() => {
                setRatingModal(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </ModalContainer>
      </Modal>
      <Modal open={cancelModal} onClose={() => setCancelModal(false)}>
        <ModalContainer>
          <h4>Cancele o pedido</h4>
          <h3>Motivo:</h3>
          <textarea></textarea>
          <div className="buttons">
            <button>{"Confirma"}</button>
            <button onClick={() => setCancelModal(false)}>Cancelar</button>
          </div>
        </ModalContainer>
      </Modal>
    </Container>
  );
};
export default AgendamentoCard;
