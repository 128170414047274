import Cards from "react-credit-cards";
import MaskedInput from "react-text-mask";

import { CreditCardContainer, PixContainer } from "./styles";

interface IData {
  cardNumber: string;
  cvc: string;
  expiry: string;
}

interface IProps {
  data: IData;
  setData: React.Dispatch<React.SetStateAction<IData>>;
  name: string;
  cpfCnpj: string;
  products: any[];
  installments: number;
  totalInstallments: number;
  setInstallments: (v: number) => void;
  setName: (v: string) => void;
  setCpfCnpj: (v: string) => void;
  value?: number;
}

const CreditCard: React.FC<IProps> = ({
  data,
  setData,
  name,
  products,
  installments,
  setInstallments,
  value,
  totalInstallments,
  setName,
  setCpfCnpj,
  cpfCnpj,
}) => {
  // const productSelector = useAppSelector((state) => state.products);

  // const findProduct = (id: string) => {
  //   return productSelector?.data?.items?.find((item) => item._id === id);
  // };
  // const totalInstallments = useMemo(() => {
  //   if (products?.length) {
  //     const allInstallments = products?.map(
  //       (product) => findProduct(product.saleProducts)?.installments || 1
  //     );

  //     return Math.max(...allInstallments);
  //   }

  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [products]);

  const formatPrice = (price: string | number) => {
    if (!price) return "";

    return price.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  return (
    <CreditCardContainer>
      <Cards
        name={name}
        expiry={data.expiry}
        cvc={data.cvc}
        number={data.cardNumber}
        locale={{ valid: "Válido até" }}
        placeholders={{ name: "NOME NO CARTÃO" }}
      />
      <div>
        <PixContainer>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome como no cartão"
          />
          <MaskedInput
            name="cpfCnpj"
            value={cpfCnpj}
            onChange={(e) => setCpfCnpj(e.target.value)}
            placeholder="CPF"
            mask={[
              /\d/,
              /\d/,
              /\d/,
              ".",
              /\d/,
              /\d/,
              /\d/,
              ".",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
            ]}
          />
        </PixContainer>
        <MaskedInput
          name="cardNumber"
          value={data.cardNumber}
          onChange={(e) => setData({ ...data, cardNumber: e.target.value })}
          placeholder="Número"
          type="tel"
          style={{ width: "100%" }}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
        />
        <div className="form">
          <MaskedInput
            placeholder="Validade"
            mask={[/\d/, /\d/, "/", /\d/, /\d/]}
            type="tel"
            onChange={(e) => setData({ ...data, expiry: e.target.value })}
            value={data.expiry}
          />
          <input
            placeholder="CVV"
            type="tel"
            onChange={(e) => setData({ ...data, cvc: e.target.value })}
            value={data.cvc}
          />
        </div>
        <select
          value={installments}
          onChange={(e) => setInstallments(Number(e.target.value))}
          style={{ width: "100%" }}
        >
          {[...Array(totalInstallments)].map((n, index) => (
            <option value={index + 1}>
              {index + 1} x de {formatPrice((value || 0) / (index + 1))}
            </option>
          ))}
        </select>
      </div>
    </CreditCardContainer>
  );
};

export default CreditCard;
