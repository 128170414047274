import { Pagination } from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb";
import Footer from "../../components/Footer";

import Header from "../../components/Header";
import LoadingPage from "../../components/LoadingPage";
import { useAppSelector } from "../../hooks";
import { useProducts } from "../../hooks/useProducts";

import NoImage from "../../img/no-image-lg.png";

import {
  Bottom,
  Card,
  Container,
  PaginationContainer,
  Wrapper,
} from "./styles";
import { handleToast } from "../../utils/handleToast";

const Products: React.FC = () => {
  const token = useAppSelector((state) => state.token);
  const address = useAppSelector((state) => state.address.data);
  const { data, handleProducts, loading: fetchLoading } = useProducts();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const location = useLocation();

  const { state }: any = location;

  const user = useAppSelector((state) => state.user);

  const formatPrice = (price: string | number) => {
    return price.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        await handleProducts({
          page,
          rowsPerPage: 12,
          carYear: state?.year?.from?.value,
          carModel: state?.model,
          carBrand: state?.brand?.value,
          userId: user?.data?.user?._id,
          cep: address?.zipcode || address?.cep,
        });
      } catch (e) {
        handleToast(true, "Erro ao trazer produtos");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetch();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    state?.brand.value,
    state?.model.name,
    state?.year.from,
    state?.year.value,
    user?.data?.user?._id,
  ]);

  if (loading || fetchLoading) {
    return <LoadingPage />;
  }

  if (!data?.items?.length && !loading && !fetchLoading) {
    return (
      <Wrapper>
        <Header />
        <Breadcrumb />
        <h2>Nenhum produto encontrado.</h2>
        <Container></Container>
        <Footer />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header />
      <Breadcrumb />
      <Container>
        {data?.items.map((item) => (
          <Card key={item._id}>
            <Link to={token ? `/item-produto/${item._id}` : "/login"}>
              {item.images != null ? (
                <img
                  src={
                    item?.images[0]?.image64
                      ? `data:image/png;base64, ${item?.images[0]?.image64}`
                      : NoImage
                  }
                  alt={item?.title}
                />
              ) : (
                <img src={NoImage} alt={item?.title} />
              )}

              <Bottom>
                <h3>{item.title}</h3>
                <p className="brand">
                  Marca: <span>{item.organization?.name || " "}</span>
                </p>
                <p className="price-over-time">
                  {item?.installments || 1}x de{" "}
                  {formatPrice(item.price / item?.installments || 1)}
                </p>
                <p className="total-price">{formatPrice(item.price)}</p>
                {/* <div className="stars">
                  <Star filled={undefined} onClick={undefined} />
                </div> */}
              </Bottom>
            </Link>
          </Card>
        ))}
      </Container>
      <PaginationContainer>
        <Pagination
          count={Math.ceil((data?.itemsTotal || 1) / 10)}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </PaginationContainer>
      <Footer />
    </Wrapper>
  );
};

export default Products;
