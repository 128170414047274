import { Wrapper, Container, NavMenu, Vehicles } from "./styles";

import { FaBars } from "react-icons/fa";

import Dropdown from "../Dropdown";
import api from "../../services/api";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Address from "./Address";
import { handleToast } from "../../utils/handleToast";

interface IReactSelect {
  value: any;
  label: string;
}

interface IProps {
  loadingPage?: boolean;
}

const Navbar: React.FC<IProps> = ({ loadingPage }) => {
  const [carBrand, setCarBrand] = useState<IReactSelect | null>();
  const [carModel, setCarModel] = useState<IReactSelect | null>();
  const [carYearFrom, setCarYearFrom] = useState<IReactSelect | null>();

  const [brands, setBrands] = useState<IReactSelect[]>();
  const [cars, setCars] = useState<any>([]);
  const [models, setModels] = useState<IReactSelect[]>();
  const [yearsFrom, setYearsFrom] = useState<IReactSelect[]>();
  const [disabled] = useState({ model: false });

  const navigate = useNavigate();

  useEffect(() => {
    async function fetch() {
      if (!loadingPage) {
        const res = await api.get("/carmodel/logout");

        if ((res as any)?.error) {
          handleToast(true, "Ocorreu um erro ao trazer os veículos");
        } else {
          const brands = res?.data?.data?.items?.map((item: any) => ({
            label: item._id,
            value: item._id,
          }));

          setBrands(brands);
          setCars(res?.data?.data?.items);

          const yearsFrom = res?.data?.data?.totalYears?.map((item: any) => ({
            label: item,
            value: item,
          }));

          setYearsFrom(yearsFrom);
        }
      }
    }

    fetch();
  }, [loadingPage]);

  useEffect(() => {
    if (carBrand?.value && cars?.length) {
      const models = cars
        .find((item: any) => item._id === carBrand.value)
        ?.cars?.map((item: any) => {
          return {
            label: item.name,
            value: item.name,
          };
        });

      // setCarModel(null);
      setModels(models);

      const years = cars
        .find((item: any) => item._id === carBrand.value)
        ?.distinctYears?.map((item: any) => {
          return {
            label: item,
            value: item,
          };
        });

      setYearsFrom(years);

      if (!years?.map((item: any) => item.value).includes(carYearFrom?.value)) {
        setCarYearFrom(null);
      }
    }
  }, [carBrand, cars, carYearFrom?.value]);

  const customStyles = {
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      minHeight: "30px",
      height: "30px",
      width: "180px",
      padding: "0 8px",
      borderRadius: "5px",
      border: "1px solid #ececec",
      background: isDisabled ? "#eeeeee" : "white",
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      height: "30px",
      width: "180px",
      padding: "0 8px",
    }),

    input: (provided: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),

    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "30px",
    }),
  };

  return (
    <>
      <Wrapper>
        <Container>
          <Dropdown button={<FaBars size={25} style={{ color: "white" }} />}>
            <NavMenu>
              <Link to="/cadastro-usuario">Cadastro</Link>
              <Link to="/contato">Fale conosco</Link>
              <a
                href="https://seller-lubbri.omegacotech.com.br"
                target="_blank"
                rel="noreferrer noopener"
              >
                Venda seu produto
              </a>

              <Link to="/perguntas-frequentes">Dúvidas frequentes</Link>
            </NavMenu>
          </Dropdown>
          <Vehicles>
            <Select
              styles={customStyles}
              onChange={(e: any) => setCarBrand(e)}
              options={brands}
              placeholder="Montadora"
              value={carBrand}
              isClearable
            />
            <Select
              styles={customStyles}
              onChange={(e: any) => setCarModel(e)}
              options={models}
              placeholder="Modelo"
              value={carModel}
              isClearable
              isDisabled={disabled.model}
            />
            <Select
              styles={customStyles}
              onChange={(e: any) => setCarYearFrom(e)}
              options={yearsFrom}
              placeholder="Ano"
              value={carYearFrom}
              isClearable
            />
            <button
              disabled={!carModel}
              onClick={() =>
                navigate("/produtos", {
                  state: {
                    model: carModel?.value,
                    brand: carBrand,
                    year: {
                      from: carYearFrom,
                      to: carYearFrom,
                    },
                  },
                })
              }
            >
              Buscar
            </button>
          </Vehicles>
        </Container>
        <Address />
      </Wrapper>
    </>
  );
};

export default Navbar;
